import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link for routing

import list5 from '../../assets/post-img-1.jpg';  // Example image
import list6 from '../../assets/post-img-2.jpg';  // Example image

const MyComponent = () => {
  const [categories, setCategories] = useState([]);
  const [latestNews, setLatestNews] = useState([]);

  // Fetch latest news and categories
  useEffect(() => {
    axios.get('https://roloeganga.net/admin/api/latest/news')
      .then((response) => {
        setLatestNews(response.data.news);
      })
      .catch((error) => {
        console.error('Error fetching latest news:', error);
      });

    axios.get('https://roloeganga.net/admin/api/categories')
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  // Helper function to remove HTML tags from a string
  const removeHtmlTags = (str) => {
    return str.replace(/<[^>]*>/g, ''); // Removes anything between < > (HTML tags)
  };

  // Helper function to limit title to 6 words
  const limitTitle = (title) => {
    if (!title) return '';
    const words = title.split(' ');
    return words.slice(0, 6).join(' ') + (words.length > 6 ? '...' : '');
  };

  // Helper function to limit description to 100 characters
  const limitDescription = (description) => {
    if (!description) return '';
    return description.length > 100 ? description.slice(0, 100) + '...' : description;
  };

  // Array of colors for the buttons
  const buttonColors = [
    '#FF5733',  // Red
    '#33C4FF',  // Blue
    '#28A745',  // Green
    '#FF914D',  // Orange
    '#F9A825',  // Yellow
    '#9C27B0'   // Purple
  ];

  // Common button style used for both category buttons and news card buttons
  const buttonStyles = {
    marginBottom: '10px',
    fontSize: '12px',  // Smaller font size
    textAlign: 'left',
    padding: '8px 16px', // Smaller padding for a smaller button
    color: '#fff',
    width: 'fit-content',
    height: '36px', // Slightly shorter button
    clipPath: 'polygon(0 0, 100% 0, 90% 100%, 0 100%)',
    transition: 'all 0.3s ease-in-out',
    display: 'inline-block',
    borderRadius: '0',
    '&:hover': {
      opacity: 0.8,  // Make button slightly transparent on hover
    },
  };

  return (
    <Grid container padding="40px 0px" spacing={2} style={{ fontFamily: 'var(--font-oxygen)', margin: 0 }}>
      {/* Heading for Latest Posts */}
      <Grid item xs={12} sx={{ textAlign: 'start' }}>
        <Typography 
          variant="h5" 
          sx={{
            fontWeight: 'bold',
            marginBottom: '20px',
            color: '#333',
            fontSize: '1.8rem', // Slightly larger title font size
            fontFamily: 'var(--font-oxygen)', // Apply Oxygen font
          }}
        >
          Últimas Publicaciones
        </Typography>
      </Grid>

      <Grid container item xs={14} spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        {/* Left Column with Latest News */}
        <Grid item xs={14} sm={8} md={8} lg={8} sx={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
          <Grid container spacing={3} justifyContent="center">
            {Array.isArray(latestNews) && latestNews.slice(0, 3).map((news, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card sx={{
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.15)',
                  },
                }}>
                  {/* Image Link */}
                  <Link to={`/${news.slug}`} style={{ textDecoration: 'none' }}>
                    <CardMedia
                      component="img"
                      image={news.image || list5}
                      alt="News Image"
                      sx={{
                        width: '100%',
                        height: '180px',
                        objectFit: 'cover',
                      }}
                    />
                  </Link>

                  <CardContent sx={{ padding: '16px' }}>
                    {/* Category Button */}
                    <Link to={`/categoría/${news.category}`} style={{ textDecoration: 'none' }}>
                      <Button
                        sx={{
                          ...buttonStyles,
                          backgroundColor: buttonColors[index % buttonColors.length], // Use different color based on index
                        }}
                      >
                        {news.category || 'Category'}
                      </Button>
                    </Link>

                    {/* Title */}
                    <Link to={`/${news.slug}`} style={{ textDecoration: 'none' }}>
                      <Typography 
                        variant="h6" 
                        sx={{
                          fontWeight: 'bold',
                          marginTop: '12px',
                          fontSize: '16px',
                          color: '#333',
                          lineHeight: 1.4,
                          fontFamily: 'var(--font-oxygen)', // Apply Oxygen font
                        }}
                      >
                        {limitTitle(removeHtmlTags(news.title || 'No Title Available'))} {/* Remove HTML tags */}
                      </Typography>
                    </Link>

                    {/* Description */}
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ 
                        marginTop: '10px', 
                        fontSize: '14px',
                        fontFamily: 'var(--font-oxygen)', // Apply Oxygen font
                      }}
                    >
                      {limitDescription(removeHtmlTags(news.description || 'No description available'))} {/* Remove HTML tags */}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Right Column with Category Buttons */}
        <Grid item xs={12} sm={6} md={8} lg={4}  spacing={6} sx={{ display: 'flex', justifyContent: 'left', padding: '16px' }}>
          <Card sx={{
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '10px',
            padding: 2,
            backgroundColor: '#f9f9f9',
            width:"100%",
          }}>
            <div style={{
              marginBottom: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            backgroundColor: '#f9f9f9',
             width: '90%', // Ensure full width for proper button alignment
            }}>
              <Typography 
                variant="h6" 
                sx={{
                  fontWeight: 'bold',
                  marginBottom: '15px',
                  fontSize: '1.2rem',
                  fontFamily: 'var(--font-oxygen)', // Apply Oxygen font
                }}
              >
                Categorías
              </Typography>

              {Array.isArray(categories) && categories.map((category, index) => (
                <Link to={`/categoría/${category.name}`} key={index} style={{ textDecoration: 'none' }}>
                  <Button
                    sx={{
                      ...buttonStyles,
                      backgroundColor: buttonColors[index % buttonColors.length], // Assign a unique color to each button
                    }}
                  >
                    {category.name}
                  </Button>
                </Link>
              ))}
            </div>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyComponent;
